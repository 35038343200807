import React, { useState, useEffect } from "react";

const ChatBubbleDropdown = ({
  options,
  onSelect,
  initialSelected,
  isDropdownOpen,
  onToggle,
  disabled,
  setDropDownOpen,
}) => {
  const [selectedOption, setSelectedOption] = useState(initialSelected || "");
  const [activeParent, setActiveParent] = useState(null);

  useEffect(() => {
    if (options.length > 0 && !initialSelected) {
      setSelectedOption(options[0].label);
    } else {
      setSelectedOption(initialSelected);
    }
  }, [options, initialSelected]);

  const handleOptionClick = (option) => {
    if (disabled) return;

    // If the option has nested children, set it as active parent
    if (option.children) {
      setActiveParent(option.key === activeParent ? null : option.key);
      return;
    }

    // Otherwise, select the option and close dropdown
    setSelectedOption(option.label);
    if (onSelect) onSelect({ key: option.key, label: option.label });

    setActiveParent(null); // Close nested dropdown if open
    setDropDownOpen(false);
  };

  return (
    <div className="relative max-w-xl mx-auto mt-4">
      <div
        className={`relative bg-gradient-mild text-white p-4 rounded-full font-semibold text-center cursor-pointer flex items-center justify-between ${
          disabled ? "opacity-50 cursor-not-allowed" : ""
        }`}
        onClick={disabled ? undefined : onToggle}
      >
        <span>{selectedOption || "Select an option"}</span>

        <svg
          className={`w-5 h-5 ml-2 text-white transform transition-transform duration-300 ${
            isDropdownOpen ? "rotate-180" : "rotate-0"
          }`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>

        <div className="absolute -left-[14.2px] bottom-4 w-0 h-0 border-l-[12px] border-t-[12px] border-t-primaryColor border-r-[12px] border-r-transparent transform rotate-[70deg]"></div>
      </div>
      {isDropdownOpen && (
        <div
          className={`absolute top-full left-0 w-full z-50 bg-white text-primaryColor mt-1 rounded shadow-lg overflow-hidden transition-all duration-300 ease-in-out ${
            isDropdownOpen ? "max-h-60 opacity-100" : "max-h-0 opacity-0"
          }`}
        >
          {options.map((option) => (
            <div key={option.key}>
              <div
                onClick={() => handleOptionClick(option)}
                className={`px-4 py-2 cursor-pointer hover:bg-primaryColor hover:text-white ${
                  disabled ? "cursor-not-allowed" : ""
                } flex justify-between items-center`}
              >
                {option.label}
                {option.children && (
                  <svg
                    className="w-4 h-4 ml-2 text-primaryColor"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                )}
              </div>
              {/* Render nested dropdown */}
              {activeParent === option.key && option.children && (
                <div className="ml-6 mt-1 border-l pl-4">
                  {option.children.map((child) => (
                    <div
                      key={child.key}
                      onClick={() => handleOptionClick(child)}
                      className="px-4 py-2 cursor-pointer hover:bg-primaryColor hover:text-white"
                    >
                      {child.label}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ChatBubbleDropdown;
