import "react-toastify/dist/ReactToastify.css";
import "./AddProfile.css";
import { useState, useEffect } from "react";
import OnBoardingLayout from "../../../layouts/OnBoardingLayout";
import { useUser } from "../../DataContext";
import SocialMediaConnection from "../../../common/SocialMediaConnection";
import { apiClient } from "../../../config/axios";
import { createFormDataWithRepeatedKeys } from "../../../utils/CreateFormDataWithRepeatedKeys";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";

const AddProfile = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const [connectedProfile, setConnectedProfile] = useState(null);
  const [error, setError] = useState(null);
  const formData = createFormDataWithRepeatedKeys();

  const fetchUserDetails = async () => {
    try {
      const { data } = await apiClient.get("api/user");
      setConnectedProfile(data);
    } catch (err) {
      setError(err);
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  useEffect(() => {
    const uploadDankestPhoto = async () => {
      const dankestPhotos = localStorage.getItem("uploadedProfilePicture");

      if (dankestPhotos && connectedProfile?.id) {
        try {
          await apiClient.post(
            `/api/troll-register/dankest/${connectedProfile.id}`,
            formData
          );
          toast.success("image successfully uploaded");
          localStorage.removeItem("uploadedProfilePicture");
        } catch (err) {
          setError(err);
        }
      }
    };

    if (connectedProfile) {
      uploadDankestPhoto();
    }
  }, [connectedProfile]);

  return (
    <OnBoardingLayout selected={1}>
      <div className="bg-white w-full px-3 pt-3 h-screen mt-2">
        <button
          onClick={() => navigate("/")}
          className="flex items-center gap-2 hover:underline font-bold text-primaryColor hover:text-hoverBaseColor mb-4 ml-4"
        >
          <IoArrowBack size={20} />
          <span>Back to Home</span>
        </button>
        <div className="flex flex-col items-center mt-6 lg:mt-24 md:w-8/12 w-full mx-auto ">
          <h1 className="text-2xl text-grey text-center font-semibold">
            Welcome {user?.firstName}!
          </h1>
          <p className="text-lg text-grey -mt-1 text-center">
            Add social media profiles to your Recant.ai account.
          </p>
        </div>
        <SocialMediaConnection />
      </div>
    </OnBoardingLayout>
  );
};

export default AddProfile;
