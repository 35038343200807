import React, { useState } from 'react';
import AdminLayout from '../Admin/AdminLayout';
import { useQuery } from 'react-query';
import { apiClient } from '../../config/axios';
import Loader from '../../components/Loader';
import {
  Avatar,
  TablePagination,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';

export const fetchTrollList = async () => {
  const response = await apiClient.get(`api/troll-register/admin`);
  return response;
};

function ReportedTrollList() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const { data, isLoading, error } = useQuery('[trollList]', () =>
    fetchTrollList()
  );

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const headCells = [
    { id: 'name', align: 'left', label: 'Name' },
    { id: 'social', align: 'left', label: 'Social Media' },
    { id: 'status', align: 'center', label: 'Status' },
    { id: 'verify', align: 'center', label: 'Verify' },
  ];

  if (isLoading) {
    return (
      <AdminLayout>
        <div className="h-[70vh]">
          <Loader />
        </div>
      </AdminLayout>
    );
  }

  if (error) {
    return (
      <AdminLayout>
        <div className="flex items-center justify-center h-[70vh]">
          <div className="text-base lg:!text-xl font-poppins font-[600]">
            Data Not Found!
          </div>
        </div>
      </AdminLayout>
    );
  }

  return (
    <AdminLayout>
      <div className="">
        <div className="flex justify-between mb-[40px] items-center">
          <h4 className="font-bold  textSecondary">Reported Troll List</h4>
        </div>
        <TableContainer>
          <Table sx={{ minWidth: 600 }}>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                {headCells.map((headCell) => (
                  <TableCell key={headCell.id} align={headCell.align}>
                    {headCell.label}
                  </TableCell>
                ))}
                {/* <TableCell align="right">Actions</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.data
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((list) => (
                  <TableRow key={list.id}>
                    <TableCell padding="checkbox">
                      {/* <Checkbox color="primary" /> */}
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        navigate(`/admin-reported-troll-list/${list.id}`);
                      }}
                      className="cursor-pointer"
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar>
                          <PersonIcon />
                        </Avatar>
                        <Box ml={2}>
                          <Typography variant="subtitle1">
                            {`${list.name}`}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell align="center">{list.socialMedia}</TableCell>
                    <TableCell align="center">{list.status}</TableCell>
                    <TableCell align="center">{list.verify}</TableCell>

                    {/* <TableCell align="right">
                      <IconButton aria-label="edit">
                        <EditIcon />
                      </IconButton>
                      <IconButton aria-label="delete">
                        <DeleteIcon />
                      </IconButton>
                    </TableCell> */}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data?.data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </AdminLayout>
  );
}

export default ReportedTrollList;
