export const options = {
  responseTone: [
    { key: "somewhat_jovial", label: "Jovial" },
    { key: "firm_friendly", label: "Firm but kinda friendly" },
    { key: "serious_polite", label: "Serious but polite" },
    { key: "very_serious", label: "Serious" },
  ],
  messageNature: [
    {
      key: "violent_threats",
      label: "Explicit violent threats or sexual solicitation",
    },
    { key: "creepy_messages", label: "Generally creepy or weird behavior" },
    { key: "crude_banter", label: "Swearing or crude banter" },
  ],
  idealOutcome: [
    { key: "back_off", label: "Back off completely" },
    { key: "beg_for_forgiveness", label: "Beg for forgiveness" },
    { key: "acknowledge_warning", label: "Acknowledge warning" },
  ],
  privacyPreference: [
    {
      key: "no",
      label: "No",
      children: [
        { key: "keep_private", label: "And keep this private" },
        {
          key: "warn_sender",
          label: "But warn the sender about public exposure should they persist ",
        },
      ],
    },
    {
      key: "yes",
      label: "Yes",
      children: [
        {
          key: "publish_with_conditions",
          label:
            "I want them to pay to take it back, but I want to keep it between me and the sender",
        },
        {
          key: "publish_immediately",
          label: "I want to warn the sender that I may publish if they do not recant",
        },
      ],
    },
  ],
};
