import React, { useState } from "react";

import Logo from "../../../assets/images/RecantNewLogo.png";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../DataContext";
import TopNavBar from "../../../layouts/OnBoardingLayout/TopNavBar";
import JoinBetaButton from "../NavItem";
import WWYMSButton from "../WWYMSButton";
import { RxHamburgerMenu } from "react-icons/rx";
import { useTheme, useMediaQuery } from "@mui/material";
import ResponsiveDrawer from "../../Admin/Drawer";
import { RiApps2Fill, RiSendPlaneFill } from "react-icons/ri";
import { HiOutlineLogout } from "react-icons/hi";

const landingPageDrawer = [
  {
    key: 1,
    link: "/login",
    // icon: RiApps2Fill,
    title: "Login",
  },
  {
    key: 3,
    link: "/signup",
    // icon: RiApps2Fill,
    title: "Join Beta",
  },
  {
    key: 4,
    link: "https://wwyms.recant.ai/",
    // icon: RiApps2Fill,
    title: "WWYMS",
  },

  {
    key: 5,
    link: "/dashboard",
    // icon: RiApps2Fill,
    title: "Dashboard",
  },
  {
    key: 6,
    link: "/logout",
    // icon: HiOutlineLogout,
    title: "Logout",
  },
];

const Header = () => {
  const navigate = useNavigate();
  const { user } = useUser();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const [mobileOpen, setMobileOpen] = useState(false);

  const filteredLandingPageDrawer = landingPageDrawer.filter((item) => {
    if (user) {
      return (
        item.title === "Dashboard" ||
        item.title === "Logout" ||
        item.title === "WWYMS"
      );
    } else {
      return (
        item.title === "Login" ||
        item.title === "Join Beta" ||
        item.title === "WWYMS"
      );
    }
  });

  return (
    <div className="fixed top-0 left-0 w-full z-50 bg-white border-b-[1px] border-greyLight">
      <div className="max-w-[1440px] mx-auto">
        <div className="flex justify-between items-center py-[16px] lg:pt-[20px] px-[15px] lg:px-[60px]">
          <img src={Logo} alt="logo" className="w-[120px] cursor-pointer" />
          {!isDesktop && (
            <RxHamburgerMenu
              className="mt-1"
              size={20}
              onClick={() => setMobileOpen(!mobileOpen)}
            />
          )}
          {isDesktop && (
            <>
              <div className="flex gap-2.5 lg:gap-4 items-center">
                {user ? (
                  <TopNavBar />
                ) : (
                  <>
                    <button
                      className={` text-sm  text-baseColor cursor-pointer font-bold lg:font-normal lg:!text-base font-poppins hover:font-semibold mr-2`}
                      onClick={() => navigate(`/login`)}
                    >
                      Login
                    </button>
                    <JoinBetaButton label={"Join Beta"} />
                    <WWYMSButton />
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      {!isDesktop && (
        <ResponsiveDrawer
          open={mobileOpen}
          handleDrawerToggle={() => setMobileOpen(!mobileOpen)}
          sideBarItems={filteredLandingPageDrawer}
        />
      )}
    </div>
  );
};

export default Header;
